
import './styles/quasar.scss'
import '@quasar/extras/material-icons/material-icons.css'
import Cookies from 'quasar/src/plugins/Cookies.js';;
import langFr from 'quasar/lang/fr'

// To be used on app.use(Quasar, { ... })
export default {
  lang: langFr,
  config: {},
  plugins: {
    Cookies
  }
}
